import * as React from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"

import cn from '@components/utils/cn'
import Icon from "@components/utils/Icon"

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("tw-border-b", className)}
    {...props}
  />
))
AccordionItem.displayName = "AccordionItem"

type JustifyOptions = "center" | "between"

type AccordionTriggerProps = React.ComponentPropsWithoutRef<
  typeof AccordionPrimitive.Trigger
> & {
  justify?: JustifyOptions
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, justify = "between", ...props }, ref) => {

  const justifyClass = justify === "center" ? "tw-justify-center" : "tw-justify-between"

  return (
    <AccordionPrimitive.Header className="tw-flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "tw-flex tw-flex-1 tw-items-center tw-py-4 tw-text-sm tw-font-medium tw-transition-all hover:tw-underline tw-text-left",
          "[&[data-state=open]>svg]:tw-rotate-180",
          justifyClass,
          className
        )}
        {...props}
      >
        {children}
        <Icon
          className="tw-h-4 tw-w-4 tw-shrink-0 tw-text-muted-foreground tw-transition-transform tw-duration-200"
          type="chevron-down"
        />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})
AccordionTrigger.displayName = "AccordionTrigger"


const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="tw-overflow-hidden tw-text-sm data-[state=closed]:tw-animate-accordion-up data-[state=open]:tw-animate-accordion-down"
    {...props}
  >
    <div className={cn("tw-pb-4 tw-pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
