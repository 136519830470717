import { useState } from 'react'

import { Supporter } from '@frontend/graphql/types.generated'
import { scopedTranslation } from '@utils/I18n'
import { Button, Checkbox, Dialog } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import useUnassignShiftSlotMuation from './useUnassignShiftSlotMutation'
import type { ShiftSlotWithShift } from '../shifts/ShiftsTable'

const t = scopedTranslation('components.unassign_shift')

type UnassignShiftDialogProps = {
  slot: ShiftSlotWithShift
  supporter: Pick<Supporter, 'firstName'>
  onShiftUpdate: () => void
}

const UnassignShiftSlotDialog = ({ slot, supporter, onShiftUpdate }: UnassignShiftDialogProps) => {
  const unassignShiftSlot = useUnassignShiftSlotMuation()
  const [notifySupporter, setNotifySupporter] = useState(true)

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild={true}>
        <Button
          rank="link"
          leadingIcon="minus-circle"
          color="danger"
          size="sm"
          ariaLabel={t('unassign', { name: supporter.firstName })}
        >
          {t('unassign', { name: supporter.firstName })}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <AppErrorsAlert errorsPath="unassignShift" />
        <Dialog.Header>
          <Dialog.Title>{t('unassign', { name: supporter.firstName })}</Dialog.Title>
        </Dialog.Header>
        <p>{t('confirmation', { name: supporter.firstName })}</p>
        <div className="tw-flex tw-items-center">
          <Checkbox
            name="notifySupporter"
            defaultChecked={notifySupporter}
            onClick={() => {
              setNotifySupporter(!notifySupporter)
            }}
          >
            <div className="tw-pl-2">{t('notify_supporter', { first_name: supporter.firstName })}</div>
          </Checkbox>
        </div>
        <Dialog.Footer>
          <Dialog.Close>{t('cancel')}</Dialog.Close>
          <Button
            onClick={() => {
              unassignShiftSlot({ id: slot.id, notifySupporter, onSuccess: onShiftUpdate })
            }}
            color="danger"
          >
            {t('unassign', { name: supporter.firstName })}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default UnassignShiftSlotDialog
