import { DateTime } from 'luxon'

// Turns out CLDR (Common Locale Data Repository) used to have the wrong data
// for 'en-AU' setting it to 24h time instead of 12h (am/pm). They've fixed it since
// but Chrome and Edge haven't updated and are still resolving it wrong.
// This function takes ISO8601 and returns hour:minutes according to the correct
// format.
// It uses Luxon's ability to set the timezone to 'system' and then return the zonename
// for us to query and force 12h for Australian timezones
const timeZoneFormattingOptions = ({ dateTime }: { dateTime: DateTime }) => {
  const zoneName = dateTime.setZone('system').zoneName

  let formattingOptions = {}

  if (zoneName?.startsWith('Australia/') || zoneName?.startsWith('Pacific/')) {
    formattingOptions = { hourCycle: 'h23', locale: 'en-AU', hour12: true }
  }

  return formattingOptions
}

type TimeFormatter = {
  dateTime: DateTime
  toHoursMinutes: () => string
  to24hours: () => string
  toDate: () => string
}

const timeFormatter = {
  fromISO: (isoDate: string): TimeFormatter => {
    const dateTime = DateTime.fromISO(isoDate, { setZone: true })

    return {
      dateTime,
      toHoursMinutes: () => {
        const formattingOptions = timeZoneFormattingOptions({ dateTime })
        const formattedTime = dateTime.toLocaleString({ ...DateTime.TIME_SIMPLE, ...formattingOptions })
        // in certain timezones we get `\u202f` for a space. This standardises on normal space
        // in certain timezones we get AM/PM instead of am/pm. This staradises on lowercase
        return formattedTime.toLowerCase().replace(/\u202f(am|pm)/i, ' $1')
      },
      to24hours: () => {
        return dateTime.toFormat('HH:mm')
      },
      toDate: () => {
        return dateTime.toFormat('dd/MM/yyyy')
      },
    }
  },
}

export default timeFormatter
