import useMutation from '@hooks/useGQLMutation'
import { useErrorsStore } from '@stores/errorsStore'

import { scopedTranslation } from '@frontend/utils/I18n'

import { ConfirmRosterMutation } from './mutations'
import type { ConfirmRosterDocument } from '@frontend/graphql/types.generated'

import type { Roster } from '@frontend/graphql/types.generated'

type ConfirmRosterValues = {
  rosterId: Roster['id']
  notifySupporters: boolean
  onSuccess: () => void
}

const t = scopedTranslation('components.confirm_shifts')

const useConfirmDraftRosterMutation = () => {
  const { setErrors: setAppErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof ConfirmRosterDocument>(ConfirmRosterMutation)

  const confirmRoster = async ({ rosterId, notifySupporters, onSuccess }: ConfirmRosterValues) => {
    clearErrors('confirmRoster')
    mutate(
      {
        input: {
          id: rosterId,
          notifySupporters: notifySupporters,
        },
      },
      {
        onSuccess: (response) => {
          if (response?.confirmRoster?.success) {
            onSuccess()
          } else {
            setAppErrors({
              errorsPath: 'confirmRoster',
              errors: response?.confirmRoster?.errors ?? [
                {
                  code: 'CONFIRM_ROSTER_ERROR',
                  message: t('errors.unable_to_confirm_roster_generic_error'),
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'confirmRoster',
            errors: [
              {
                code: 'CONFIRM_ROSTER_ERROR',
                message: t('errors.unable_to_confirm_roster_generic_error'),
              },
            ],
          })
        },
      }
    )
  }

  return confirmRoster
}

export default useConfirmDraftRosterMutation
