import usePartialReload from '@hooks/usePartialReload'
import timeFormatter from '@frontend/utils/timeFormatter'

import { scopedTranslation } from '@utils/I18n'

import ShiftRow from './ShiftRow'
import { AddShiftsDialog } from '../add_shifts/AddShiftsDialog'
import type {
  Event,
  Roster,
  Shift,
  ShiftConnection,
  ShiftSlotsConnection,
  ShiftSlot,
  Supporter,
  Maybe,
} from '@frontend/graphql/types.generated'

const t = scopedTranslation('shifts_index')

const sortShifts = (a: ShiftWithSlots, b: ShiftWithSlots): number => {
  const aStartTime = new Date(a.startTime).getTime()
  const bStartTime = new Date(b.startTime).getTime()
  const aEndTime = new Date(a.endTime).getTime()
  const bEndTime = new Date(b.endTime).getTime()

  if (aStartTime !== bStartTime) {
    return aStartTime - bStartTime
  }

  if (aEndTime !== bEndTime) {
    return aEndTime - bEndTime
  }

  return a.roleName.localeCompare(b.roleName)
}

const DayDetails = ({ event, date }: { event: Pick<Event, 'name' | 'startTime' | 'endTime'>; date: string }) => {
  const formattedDate = timeFormatter.fromISO(date).toDate()
  const eventStartTime = timeFormatter.fromISO(event.startTime).toHoursMinutes()
  const eventEndTime = timeFormatter.fromISO(event.endTime).toHoursMinutes()

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <h1 className="tw-text-xl tw-font-medium">{formattedDate}</h1>
      <h1 className="tw-text-xl tw-font-medium">
        {eventStartTime} - {eventEndTime}
      </h1>
    </div>
  )
}

export type ShiftSlotWithShift = Pick<ShiftSlot, 'id'> & {
  shift: Pick<Shift, 'id' | 'startTime' | 'endTime' | 'roleName'>
  supporter: Maybe<Pick<Supporter, 'id' | 'firstName' | 'fullName'>>
}

export type ShiftWithSlots = Pick<Shift, 'id' | 'roleName' | 'startTime' | 'endTime' | 'draft' | 'allowSelfSelect'> & {
  shiftSlots: Pick<ShiftSlotsConnection, 'totalCount' | 'assignedCount'> & {
    nodes: Array<ShiftSlotWithShift> | []
  }
}

type ShiftsTableProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime'> & {
    roster:
      | (Pick<Roster, 'id'> & {
          shifts?: Pick<ShiftConnection, 'totalCount'> & {
            nodes: Array<ShiftWithSlots> | []
          }
        })
      | null
  }
  date: string
}

export const ShiftsTable = ({ event, date }: ShiftsTableProps) => {
  const handleShiftUpdate = () => {
    usePartialReload('', ['event'], () => {})
  }

  const dateDateTime = timeFormatter.fromISO(date).dateTime

  const shifts = (event.roster?.shifts?.nodes ?? [])
    .filter((shift): shift is ShiftWithSlots => shift !== null)
    .filter((shift) => {
      const formattedShift = timeFormatter.fromISO(shift.startTime).dateTime
      return dateDateTime.hasSame(formattedShift, 'day')
    })

  return (
    <>
      <div className="tw-flex tw-flex-col tw-touch-auto tw-gap-4 sm:tw-rounded-xl">
        <DayDetails date={date} event={event} />
        <div className="tw-flex">
          <div className="tw-w-full tw-text-gray-900 tw-font-medium tw-h-12 tw-border-r-2 tw-border-r-gray-200 sm:tw-w-[21rem]">
            <div className="tw-flex tw-h-full">
              <div className="tw-bg-gray-100 tw-w-full tw-px-6 tw-py-3 sm:tw-rounded-tl-lg">{t('shifts')}</div>
            </div>
          </div>
          <div className="tw-h-12 tw-flex-grow tw-hidden sm:tw-block">
            <div className="tw-flex tw-h-full">
              <div className="tw-bg-gray-100 tw-h-full tw-w-full tw-px-6 tw-py-3 tw-rounded-tr-lg"></div>
            </div>
          </div>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-8">
          <div className="tw-flex tw-flex-col tw-gap-4 md:tw-pt-0">
            {[...shifts].sort(sortShifts).map((shift, index) => (
              <ShiftRow key={shift.id} shift={shift} index={index} handleShiftUpdate={handleShiftUpdate} />
            ))}
          </div>

          <div className="tw-flex tw-w-full tw-items-center tw-justify-center md:tw-w-[21rem]">
            <AddShiftsDialog date={date} event={event} onShiftUpdate={handleShiftUpdate} />
          </div>
        </div>
      </div>
    </>
  )
}
