import clsx from 'clsx'

import { scopedTranslation } from '@utils/I18n'
import timeFormatter from '@frontend/utils/timeFormatter'

import { EditShiftDialog } from '../edit_shifts/EditShiftDialog'
import { DeleteShiftDialog } from '../delete_shifts/DeleteShiftDialog'

import type { ShiftWithSlots } from './ShiftsTable'

const tAttributes = scopedTranslation('attributes.shift')
const t = scopedTranslation('components.shift_card')

const DraftStateLabel = () => {
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-mb-3 md:tw-mb-0 md:tw-px-0 tw-text-sm tw-bg-orange-50 tw-text-orange-700 sm:tw-bg-orange-500 sm:tw-text-white tw-py-2">
      <p className='tw-text-center tw-px-3'>{t('draft_state')}</p>
    </div>
  )
}

type ShiftCardProps = {
  shift: ShiftWithSlots
  onShiftUpdate: () => void
}

export const ShiftCard = ({ shift, onShiftUpdate }: ShiftCardProps) => {
  const shiftStartTime = timeFormatter.fromISO(shift.startTime).toHoursMinutes()
  const shiftEndTime = timeFormatter.fromISO(shift.endTime).toHoursMinutes()
  const isDraft = shift.draft

  return (
    <div data-testid="shift-card" className="tw-text-gray-900 tw-mb-2 sm:tw-mb-0 tw-text-md">
      <div className='tw-block sm:tw-hidden'>
        {isDraft && <DraftStateLabel />}
      </div>

      <div
        className={clsx(
          'tw-flex tw-flex-col tw-gap-3 md:tw-flex-row tw-justify-between tw-items-center',
          'md:tw-pr-2 md:tw-pl-6 md:tw-h-36',
          'md:tw-border md:tw-border-gray-300',
          'md:tw-py-4 tw-text-center md:tw-text-start'
        )}
      >
        <div>
          <p>
            {shiftStartTime} - {shiftEndTime}
          </p>
          <p>
            {tAttributes('role')}: {shift.roleName}
          </p>
        </div>
        <div className="tw-flex">
          <EditShiftDialog shift={shift} onShiftUpdate={onShiftUpdate} />
          <DeleteShiftDialog id={shift.id} onShiftDeleted={onShiftUpdate} />
        </div>
      </div>

      <div className='tw-hidden sm:tw-block'>
        {isDraft && <DraftStateLabel />}
      </div>
    </div>
  )
}
