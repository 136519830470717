import { create } from 'zustand'

import type { Event, ShiftConnection, Roster } from '@frontend/graphql/types.generated'

import ConfirmDraftRosterDialog from '../features/confirm_shifts/ConfirmDraftRosterDialog'
import { ShiftsTable, type ShiftWithSlots } from '../features/shifts/ShiftsTable'

import PageHeader from '@components/molecules/PageHeader'
import { Link } from '@atoms'

import { scopedTranslation } from '@frontend/utils/I18n'
const t = scopedTranslation('shifts_index')

type ShiftsStore = {
  groupId: string
  setGroupId: (groupId: string) => void
}

export const useShiftsStore = create<ShiftsStore>((set) => ({
  groupId: '',
  setGroupId: (groupId) => { set({ groupId }) },
}))

type ShiftsIndexProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime' | 'spanningDates'> & {
    roster:
      | (Pick<Roster, 'id'> & {
          shifts?: Pick<ShiftConnection, 'totalCount'> & {
            nodes: ShiftWithSlots[] | []
          }
        })
      | null
  }
  groupId: string
}

const ShiftsIndex = ({ event, groupId }: ShiftsIndexProps) => {
  const setGroupId = useShiftsStore((state) => state.setGroupId)
  setGroupId(groupId)

  return (
    <div className='tw-w-[100vw] lg:tw-w-sidebar-adjusted tw-min-h-screen tw-overflow-x-auto'>
      <div className="tw-flex tw-min-w-full tw-flex-col tw-gap-3 md:tw-gap-6 tw-px-3 md:tw-px-6 md:tw-w-max md:tw-flex-nowrap">
        <div>
          <Link
            rank="link"
            leadingIcon="chevron-left"
            href={`/supporter_groups/${groupId}/events/${event.id}`}
          >{t('back_link', {page_name: event.name})}</Link>
        </div>
        <PageHeader heading={t('page_header', { event_name: event.name })}/>
        <ConfirmDraftRosterDialog event={event} />
        <div className="tw-flex tw-flex-col tw-py-6 tw-gap-24">
          {(event.spanningDates ? event.spanningDates : [event.startTime]).map((date) => {
            return <ShiftsTable event={event} date={date} key={date} />
          })}
        </div>
      </div>
    </div>
  )
}

export default ShiftsIndex;
