import { useCallback, useState } from 'react'
import { Dialog, Button, Checkbox } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import { scopedTranslation } from '@utils/I18n'
import usePartialReload from '@hooks/usePartialReload'
import useFormWithErrors from '@hooks/useFormWithErrors'

import useConfirmDraftRosterMutation from './useConfirmDraftRosterMutation'

import type { Event, Roster, Shift, ShiftConnection, ShiftSlotsConnection } from '@frontend/graphql/types.generated'

type ShiftWithSlots = Pick<Shift, 'id' | 'draft'> & {
  shiftSlots: Pick<ShiftSlotsConnection, 'assignedCount'>
}

type PublishAllShiftsDialogProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime'> & {
    roster:
      | (Pick<Roster, 'id'> & {
          shifts?: Pick<ShiftConnection, 'totalCount'> & {
            nodes?: Array<ShiftWithSlots> | []
          }
        })
      | null
  }
}

const t = scopedTranslation('components.confirm_shifts')

const ConfirmDraftRosterDialog = ({ event }: PublishAllShiftsDialogProps) => {
  const [open, setOpen] = useState(false)
  const handleRosterUpdate = () => {
    usePartialReload('', ['event'], () => {})
  }

  const draftShifts = event?.roster?.shifts?.nodes?.filter((shift: { draft: boolean }) => shift.draft) || []

  const isEnabled = () => {
    return draftShifts.length > 0
  }

  const draftsHaveAssignedSlots = draftShifts.some(
    (shift) => shift.draft && shift.shiftSlots && shift.shiftSlots.assignedCount > 0
  )

  const onSuccess = () => {
    setOpen(false)
    handleRosterUpdate()
  }

  const confirmRoster = useConfirmDraftRosterMutation()

  const handlePublish = async ({ notifySupporters }) => {
    await confirmRoster({ rosterId: event.roster!.id, notifySupporters, onSuccess })
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const { control, handleSubmit, watch } = useFormWithErrors({
    defaultValues: {
      notifySupporters: true,
    },
  })

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <div className="tw-w-max">
          <Button size="xl" disabled={!isEnabled()}>
            {t('publish_all_shifts_title')}
          </Button>
        </div>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <AppErrorsAlert errorsPath="confirmRoster" />
        <form onSubmit={handleSubmit(handlePublish)} className="tw-flex tw-flex-col tw-gap-4">
          <Dialog.Header>
            <Dialog.Title>{t('publish_all_shifts_title')}</Dialog.Title>
          </Dialog.Header>
          <p>{t('confirm_and_publish_changes_helper_text')}</p>
          {draftsHaveAssignedSlots && (
            <div className="tw-flex tw-items-center">
              <Checkbox name="notifySupporters" control={control} defaultChecked={watch('notifySupporters')}>
                <div className="tw-pl-2">{t('notify_supporters_checkbox')}</div>
              </Checkbox>
            </div>
          )}
          <Dialog.Footer>
            <Dialog.Close asChild>
              <Button type="button" onClick={handleClose} rank="link" color="danger">
                {t('cancel')}
              </Button>
            </Dialog.Close>
            <Button type="submit">{t('publish')}</Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default ConfirmDraftRosterDialog
