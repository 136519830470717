import dayjs from 'dayjs'

import { useState } from 'react'
import clsx from 'clsx'

import Badge from '@atoms/Badge'
import { Link } from '@atoms'

import { Table } from "@components/molecules/Table"
import EmptyState from '@components/molecules/EmptyState'
import { ProgressBar } from '@components/molecules/ProgressBar'
import SortBy from '@components/molecules/SortBy'

import useEventsFilteringStore, { EventsFilteringProvider } from '../features/filter_events/stores/useEventsFilteringStore'

import { scopedTranslation } from '@utils/I18n'
import PageHeader from '@components/molecules/PageHeader'
import LoadingWrapper from '@components/molecules/LoadingWrapper'

const t = scopedTranslation('shifts_overview')
const tSort = scopedTranslation('shared.sort.events')

type ShiftSlot = {
  totalCount?: number
  assignedCount?: number
}

type ShiftCoverage = {
  totalShifts: number
  assignedShifts: number
}

type Shift = {
  shiftSlots?: ShiftSlot
}

type Roster = {
  shifts?: {
    nodes?: Shift[]
    totalCount?: number
  }
}

type EventRoutes = {
  editEvent: string
  editShifts: string
}

type Event = {
  id: string
  name: string
  startTime: string
  endTime?: string
  fullVenueAddress?: string
  roster: Roster
  routes: EventRoutes
  group: { id: string }
}

type EventRowProps = {
  event: Event
}

type Routes = {
  newEventRoute: string
}

type ShiftsOverviewProps = {
  events: Event[]
  routes: Routes
}


function TableHeader(): JSX.Element {
  const tableHeadClasses = clsx(
    'tw-text-gray-500 tw-text-xs tw-font-medium tw-font-bold',
    'tw-h-[44px] tw-px-6 tw-py-3 tw-items-center tw-gap-3 tw-self-stretch'
  )

  return (
    <Table.Header className={clsx('tw-bg-gray-50 tw-border-y tw-border-gray-200')}>
      <Table.Row>
      <Table.Head className={tableHeadClasses}>{t('table_header.event')}</Table.Head>
        <Table.Head className={tableHeadClasses}>{t('table_header.date')}</Table.Head>
        <Table.Head className={tableHeadClasses}>{t('table_header.shift_coverage')}</Table.Head>
        <Table.Head className={tableHeadClasses}></Table.Head>
      </Table.Row>
    </Table.Header>
  )
}

function EventRow({ event }: EventRowProps) {
  const {
    id,
    name,
    startTime,
    fullVenueAddress = "",
    roster = {},
    routes
  } = event

  function calculateShiftCoverage(shifts: Shift[]): ShiftCoverage {
    return shifts.reduce(
      (acc, shift) => {
        const shiftTotal = shift.shiftSlots?.totalCount ?? 0
        const shiftAssigned = shift.shiftSlots?.assignedCount ?? 0
  
        acc.totalShifts += shiftTotal
        acc.assignedShifts += shiftAssigned
  
        return acc
      },
      { totalShifts: 0, assignedShifts: 0 }
    )
  }

  const shifts = roster.shifts?.nodes ?? []
  const { totalShifts, assignedShifts } = calculateShiftCoverage(shifts)

  const eventStartDate = new Date(startTime)
  const formattedStartDate = dayjs(eventStartDate).format('dddd, D MMMM')

  function getFormattedEndDate(event: Event): string | null {
    const now = dayjs()
    const start = dayjs(event.startTime)
    const end = dayjs(event.endTime)
  
    if (end.isAfter(now) && end.diff(start, 'hour') >= 24) {
      return end.format('dddd, D MMMM')
    }
  
    return null
  }
  
  const formattedEndDate = getFormattedEndDate(event)
  const shiftCoverage = `${assignedShifts}/${totalShifts}`
  const shiftCoveragePercentage = (assignedShifts/totalShifts)*100

  return (
    <Table.Row key={id} className={clsx('tw-border-b last:tw-border-b-0 tw-border-gray-200')}>
      <Table.Cell>
        <div className={clsx('tw-flex tw-flex-col')}>     
          <a 
            href={routes.editEvent} 
            className={clsx('tw-text-primary-700 tw-underline tw-font-medium tw-text-md tw-w-fit')}
            aria-label={t('aria_labels.edit_event', { name })}
          >
            {name}
          </a>
          <span className={clsx('tw-text-gray-500 tw-text-sm tw-font-medium tw-font-bold')}>
            {fullVenueAddress || t('event_row.virtual_event')}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell>
        {formattedStartDate}
        {formattedEndDate && (
          <>
            <span> - </span>
            <br />
            {formattedEndDate}
          </>
        )}
      </Table.Cell>
      <Table.Cell className={clsx("md:tw-w-[30%] 2xl:tw-w-auto tw-min-w-[200px]")}>
        <div className={clsx('tw-flex tw-gap-3 tw-justify-center tw-items-center')}>
          <ProgressBar progress={shiftCoveragePercentage}/>
          {shiftCoverage}
        </div>
      </Table.Cell>
      <Table.Cell className={clsx('tw-flex tw-justify-end')}>
        <Link href={routes.editShifts} rank="link" leadingIcon='pencil-02'>
          {t('event_row.edit_shifts')}
        </Link>
      </Table.Cell>
    </Table.Row>
  )
}

function ShiftsOverviewEmptyState({ newEventRoute }: Pick<Routes, 'newEventRoute'>): JSX.Element {
  const tEmptyState = scopedTranslation('shifts_overview.empty_states.no_events_with_shifts')

  return (
    <EmptyState
      headline={tEmptyState('headline')}
      subtext={tEmptyState('subtext')}
      iconType={'calendar-date'}
    >
    <Link href={newEventRoute} rank="primary" trailingIcon='plus-circle'>
      {t('shifts_overview.add_new_event')}
    </Link>
  </EmptyState>
  )
}

function SortEvents(): JSX.Element {  
  const currentSort = useEventsFilteringStore((state) => state.currentSort)
  const setCurrentSort = useEventsFilteringStore((state) => state.setCurrentSort)

  const sortOptions = [
    { value: 'start_time asc', label: tSort('start_time_asc') },
    { value: 'start_time desc', label: tSort('start_time_desc') },
    { value: 'name asc', label: tSort('event_name_asc') },
    { value: 'name desc', label: tSort('event_name_desc') },
    { value: 'shift_coverage asc', label: tSort('shift_coverage_lowest') },
    { value: 'shift_coverage desc', label: tSort('shift_coverage_highest') },
  ]

  return (
    <SortBy
      onChange={(value) => {
        setCurrentSort(value)
      }}
      sortOptions={sortOptions}
      currentSort={currentSort}
    />
  )
}

export default function ShiftsOverview({ events, routes }: ShiftsOverviewProps) {
  function hasShifts(event: Event): boolean {
    return Boolean(event.roster?.shifts?.nodes?.length)
  }

  function eventUpcoming(event: Event): boolean {
    const now = dayjs()
    return dayjs(event.startTime).isAfter(now) || dayjs(event.endTime).isAfter(now)
  }

  const upcomingEventsWithShifts = events.filter(
    (event) => hasShifts(event) && eventUpcoming(event)
  )

  const eventCount = upcomingEventsWithShifts.length
  const [searchLoading, setSearchLoading] = useState(false)

  if (eventCount === 0) {
    return (
      <div className={clsx('tw-p-6 lg:tw-w-sidebar-adjusted')}>
        <ShiftsOverviewEmptyState newEventRoute={routes.newEventRoute}/>
      </div>
    )
  }

  return (
    <div className={clsx('tw-p-6 lg:tw-w-sidebar-adjusted')}>
      <PageHeader heading={t('page_header')}/>
      <EventsFilteringProvider 
        initProps={{ 
          loading: searchLoading,
          setLoading: setSearchLoading,
        }}
      >
        <div
          id="table-wrapper"
          className={clsx(
            'tw-rounded-lg tw-border tw-border-gray-200', 
            'tw-bg-white tw-w-full'
          )}
        >
          <div className={clsx('tw-flex tw-justify-between tw-gap-5 tw-items-center tw-px-6 tw-pb-4 tw-pt-6')}>
            <div className={clsx('tw-flex tw-items-center tw-gap-5')}>
              <h2 className={clsx('tw-text-gray-900 tw-text-lg tw-font-medium')}>
                {t('shifts_overview.upcoming_events')}
              </h2>
              <Badge color={'primary'}>
                {t('shifts_overview.event_count', { count: eventCount })}
              </Badge>
            </div>

            <Link href={routes.newEventRoute} rank='primary' trailingIcon='plus-circle'>
              {t('shifts_overview.add_new_event')}
            </Link>
          </div>
          <div className={clsx('tw-max-w-[600px] tw-px-6 tw-pb-6 tw-text-sm tw-text-gray-700')}>
             {t('page_description')}
          </div>

          <div className={clsx('tw-px-6 tw-pb-6 tw-max-w-[300px]')}>
            <SortEvents />
          </div>

          <LoadingWrapper loading={searchLoading}>
            <Table.Root>
              <TableHeader />
              <Table.Body>
                {upcomingEventsWithShifts.map((event) => 
                  <EventRow event={event} key={event.id} />
                )}
              </Table.Body>
            </Table.Root>
          </LoadingWrapper>
        </div>
      </EventsFilteringProvider>
    </div>
  )
}