import React from 'react';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useController } from 'react-hook-form';

import { scopedTranslation } from '@utils/I18n'
import timeFormatter from '@frontend/utils/timeFormatter'

import { Dialog, Button, Checkbox } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import useAssignShiftSlotMutation from './useAssignShiftSlotMutation'

import type { ShiftSlotWithShift } from '../shifts/ShiftsTable'
import SupporterSelect, { type SupporterOption } from './SupporterSelect'

const t = scopedTranslation('components.assign_shift')
const tAttributes = scopedTranslation('attributes.shift')

type AssignShiftDialogProps = {
  slot: ShiftSlotWithShift
  onShiftUpdate: () => void
}

type FormValues = {
  supporterOption: SupporterOption | null
}

const schema = yup.object().shape({
  supporterOption: yup
    .object()
    .shape({
      id: yup.string().required(),
    })
    .nullable()
    .required(tAttributes('validation.supporter_required')),
})

const AssignShiftSlotDialog = ({ slot, onShiftUpdate }: AssignShiftDialogProps) => {
  const [open, setOpen] = React.useState(false)
  const [notifySupporter, setNotifySupporter] = React.useState(true)
  const assignShiftSlot = useAssignShiftSlotMutation()

  const formattedStartTime = timeFormatter.fromISO(slot.shift.startTime).toHoursMinutes()
  const formattedEndTime = timeFormatter.fromISO(slot.shift.endTime).toHoursMinutes()

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema) as any,
  })

  const { field: supporterField } = useController({
    name: 'supporterOption',
    control,
  })

  const onSubmit = async ({ supporterOption }: FormValues) => {
    if (supporterOption) {
      await assignShiftSlot({
        id: slot.id,
        supporterId: supporterOption.id,
        notifySupporter,
        onSuccess: () => {
          setOpen(false)
          onShiftUpdate()
        },
      })
    }
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button rank="link" size="sm" leadingIcon="plus-circle" linkBackground={false}>
          {t('assign_shift')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl" position="top">
        <AppErrorsAlert errorsPath="assignShiftSlot" />
        <Dialog.Header>
          <Dialog.Title>{t('assign_supporter_to_shift')}</Dialog.Title>
        </Dialog.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
          <p>
            {tAttributes('time')}: {formattedStartTime} - {formattedEndTime}{' '}
          </p>
          <p>
            {tAttributes('role')}: {slot.shift.roleName}{' '}
          </p>
          <SupporterSelect field={supporterField} />

          <div className="tw-mt-2 tw-flex tw-items-center">
            <Checkbox
              name="notifySupporter"
              defaultChecked={notifySupporter}
              onClick={() => {
                setNotifySupporter(!notifySupporter)
              }}
            >
              <div className="tw-pl-2">{t('notify_supporter')}</div>
            </Checkbox>
          </div>

          <Dialog.Footer>
            <Dialog.Close> {t('cancel')} </Dialog.Close>
            <Button type="submit" trailingIcon="arrow-circle-broken-right">
              {t('submit')}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default AssignShiftSlotDialog
